<template>
  <div class="card h-100 p-2">
    <!-- Topo -->

    <div class="row">
      <div class="col-md-9">
        <div class="media align-items-center">
          <button class="btn d-block d-md-none pr-0 pl-1" @click="onClickVoltar">
            <i class="fa fa-arrow-left"></i>
          </button>
          <div
            class="chat-top-img rounded-circle shadow-sm ml-2 mr-3"
            :style="{backgroundImage: 'url('+( chat.anuncioFoto || '/img/empty_img_circle.png')+')'}"
          ></div>
          <div class="media-body">
            <div class="font-weight-bold chat-titulo">{{chat.anuncioTitulo}}</div>
            <small v-if="chat.proprietario">
              Comprador:
              {{chat.nomeCliente}}
            </small>
            <small v-else>
              Anunciante:
              {{chat.nomeProprietario}}
            </small>
          </div>
        </div>
      </div>
      <div class="col-md-3 text-right">
        <b-dropdown variant="none" size="sm" right class="chat-opcoes" text="Opções">
          <b-dropdown-item :to="`/anuncio/${chat.idAnuncio}`" target="_blank">Ver anúncio na loja</b-dropdown-item>
          <b-dropdown-item @click.prevent="excluirChat(chat)" >Excluir esta conversa</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!-- Mensagens -->
    <div class="container-mensagens border-top">
      <div class="p-5 text-center" v-if="loadingMensagens">Carregando...</div>
      <!-- <div class="container-mensagens" v-else> -->
      <template v-else>
        <div
          v-for="msg in mensagens"
          :key="msg.id"
          :class="mensagemPropria(msg) ? 'outgoing-msg': 'incomming-msg'"
        >
          <div>{{msg.texto}}</div>
          <div class="data">{{msg.dataHora | formatLocalDateTime}}</div>
        </div>
      </template>
    </div>

    <!-- Barra inferior -->
    <div class="container-fluid">
      <div class="row small-gutters border-top pt-2">
        <div class="col">
          <textarea
            v-model="mensagem"
            class="form-control border-0"
            rows="2"
            placeholder="Escreva uma mensagem..."
            @keydown="inputHandler"
            ref="campoMsg"
          ></textarea>
        </div>
        <div>
          <button
            class="btn btn-primary rounded-lg btn-sm btn-enviar"
            :disabled="loadingEnviar"
            @click="onClickEnviarMsg"
          >
            <i v-if="loadingEnviar" class="fas fa-sync fa-spin"></i>
            <i v-else class="fas fa-paper-plane"></i>
            <div v-if="!loadingEnviar">Enviar</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { HubConnectionBuilder } from "@aspnet/signalr";

import config from "../../config";
import smoothScroll from "../../helpers/smoothScroll";
import captureError from "../../helpers/captureError";

var chatHubConnection = undefined;

export default {
  props: ["chat", "idClienteLogado"],
  data() {
    return {
      mensagens: [],
      loadingMensagens: false,
      loadingEnviar: false,
      mensagem: "",
    };
  },
  watch: {
    chat: {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (newValue?.id != oldValue?.id) {
          this.onChangeChat();
        }
      },
    },
  },
  methods: {
    excluirChat(chat){
      this.$emit("onExcluirChat", chat)
    },
    async onChangeChat() {
      this.popularMensagens();
      await this.openChatHubConnection(this.chat.id);
    },
    onClickVoltar() {
      this.$emit("voltar");
    },
    onClickEnviarMsg() {
      if (!this.mensagem || this.mensagem.trim().length == 0) {
        return;
      }
      this.enviarMensagem();

      this.$refs.campoMsg.focus();
    },
    async enviarMensagem() {
      try {
        this.loadingEnviar = true;

        var data = {
          texto: this.mensagem,
        };
        var response = await axios.post(
          `${config.API_URL}/chat/${this.chat.id}/mensagem`,
          data
        );

        this.mensagem = "";
        this.$refs.campoMsg.value = "";
        var novaMensagem = response?.data;
        this.adicionarMensagemNaListaSeNaoExistir(novaMensagem);

        this.loadingEnviar = false;
      } catch (e) {
        captureError(e);
        this.loadingEnviar = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível enviar a mensagem. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async popularMensagens() {
      try {
        var chatId = this.chat?.id;
        if (!chatId) {
          return;
        }

        this.loadingMensagens = true;

        var response = await axios.get(
          `${config.API_URL}/chat/${chatId}/mensagem`
        );

        this.mensagens = response.data;
        this.loadingMensagens = false;

        this.mensagensScrollToBottom(false);
      } catch (e) {
        captureError(e);
        this.loadingMensagens = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível carregar as mensagens do chat. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async openChatHubConnection(chatId) {
      //Se não tiver conexão, cria
      if (!chatHubConnection) {
        chatHubConnection = new HubConnectionBuilder()
          .withUrl(config.API_URL + "/chatHub")
          .build();

        chatHubConnection.on("ReceiveMessage", this.onReceiveMessage);

        await chatHubConnection.start();
      }

      chatHubConnection.invoke("JoinChatGroup", chatId);
    },

    onReceiveMessage(mensagem) {
      this.adicionarMensagemNaListaSeNaoExistir(mensagem);
    },
    adicionarMensagemNaListaSeNaoExistir(mensagem) {
      if (!mensagem) {
        return;
      }

      var msgExistente = this.mensagens.find((item) => item.id == mensagem.id);
      if (msgExistente) {
        return;
      }

      this.mensagens.push(mensagem);
      this.mensagensScrollToBottom(true);
    },

    mensagensScrollToBottom(anim) {
      this.$nextTick(() => {
        var containerMensagens = this.$el.querySelector(".container-mensagens");
        if (containerMensagens) {
          if (anim) {
            smoothScroll.smoothScrollContainerToBottom(containerMensagens);
          } else {
            containerMensagens.scrollTop = containerMensagens.scrollHeight;
          }
        }
      });
    },

    inputHandler(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.onClickEnviarMsg();
      }
    },

    mensagemPropria(msg) {
      return (
        (this.chat.proprietario && msg.idCliente == this.chat.idProprietario) ||
        (!this.chat.proprietario && msg.idCliente == this.chat.idCliente)
      );
    },
  },
};
</script>

<style lang="less" >
.chat-opcoes button {
  padding-left: 0 !important;
}
</style>
<style lang="less" scoped>
@import "../../styles/variaveis";

.chat-top-img {
  width: 48px;
  height: 48px;
  //   background-image: url("/img/empty_img_circle.png");
  background-size: cover;
  background-position: center center;
}

.incomming-msg {
  //   color: #646464;
  background-color: @light-gray-color;
  border-radius: 15px;
  font-size: 14px;
  padding: 8px 20px 8px 20px;
  border-top-left-radius: 0;
  width: 61%;
  margin: 6px 0;
  text-align: left;
}

.incomming-msg .data {
  text-align: right;
  font-size: 80%;
}

.outgoing-msg {
  color: white;
  background-color: #05728f;
  border-radius: 15px;
  font-size: 14px;
  padding: 8px 20px 8px 20px;
  border-bottom-right-radius: 0;
  width: 61%;
  text-align: left;
  margin: 6px 0 6px auto;
}

.outgoing-msg .data {
  text-align: right;
  font-size: 80%;
}

.container-mensagens {
  overflow-y: auto;
  height: calc(100vh - 250px);
}

.btn-enviar {
  width: 58px;
  height: 58px;
}

@media (max-width: 768px) {
  .chat-titulo {
    font-size: 0.9em;
  }

  // .chat-top-img {
  //   width: 36px;
  //   height: 36px;
  // }
}
</style>