<template>
  <div class="container text-left">
    <div class="py-3">
      <h2>Adquirir plano de anúncio</h2>
    </div>

    <div v-if="loadingGeral" class="p-3">Carregando...</div>

    <div class="row" v-show="!loadingGeral">
      <div class="col-lg-4 mb-5 order-lg-2">
        <h5 class="text-muted">Plano escolhido:</h5>
        <div class="card shadow plano-escolhido border-0">
          <div class="card-body">
            <h6>{{ planoAnuncio.nome }}</h6>
            <!-- <small>{{planoAnuncio.descricao}}</small> -->
            <small class="text-muted">
              <div
                v-for="(item, index) in planoAnuncioCore.itensDescricaoPlano(
                  planoAnuncio.descricao
                )"
                :key="'p' + index"
              >
                {{ item }}
              </div>
            </small>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-4">Total</div>
              <div class="col-8 text-right">
                <small
                  class="text-muted"
                  v-if="!isNaN(valorAnterior) && valorAnterior > 0"
                >
                  De:
                  <del>{{ formatacaoMoeda(valorAnterior) }}</del>
                </small>
                <div>
                  <strong>
                    <span v-if="!isNaN(valorAnterior) && valorAnterior > 0"
                      >Por:</span
                    >
                    {{ formatacaoMoeda(valorTotal) }}
                  </strong>
                </div>

                <div class="text-success" v-if="descontoPorcentagem">
                  {{ descontoPorcentagem }}% de desconto
                </div>
                <div class="text-success" v-if="descontoValor">
                  {{ formatacaoMoeda(descontoValor) }} de desconto
                </div>
              </div>
            </div>

            <div
              class="input-group mt-3"
              v-if="!cupomAplicado && valorTotal > 0"
            >
              <input
                type="text"
                class="form-control"
                placeholder="Cupom de desconto"
                v-model="cupom"
              />
              <div class="input-group-append">
                <button
                  @click.prevent="onClickAplicarCupom"
                  :disabled="loadingCupom"
                  class="btn btn-light-gray"
                >
                  Aplicar
                </button>
              </div>
            </div>
            <div v-if="loadingCupom">
              <small>Validando o cupom...</small>
            </div>
          </div>
        </div>

        <h5 class="text-muted mt-4">Para o anúncio:</h5>

        <BlocoMeuAnuncio
          :dados="anuncio"
          :esconderOpcoes="true"
          style="height: auto"
        />
      </div>
      <div class="col-lg-8 mb-5 order-lg-1">
        <div v-show="naoPrecisaDePagamento">
          <div class="mb-3 py-3">Que ótimo! Você não precisará pagar nada.</div>

          <hr />
          <div class="row">
            <div class="offset-md-4 col-md-8 text-right">
              <button
                :disabled="loadingFinalizar"
                class="btn btn-success btn-block"
                @click.prevent="onClickRealizarPagamento()"
              >
                <span v-if="loadingFinalizar">Por favor aguarde...</span>
                <span v-if="!loadingFinalizar">Confirmar compra</span>
              </button>
            </div>
          </div>
        </div>
        <div v-show="!naoPrecisaDePagamento">
          <ValidationObserver v-slot="{ invalid, handleSubmit }">
            <form id="formStep3" @submit.prevent="handleSubmit()">
              <div class="alert alert-info">
                ATENÇÂO: O pagamento deve ser feito exclusivamente com
                <b>cartão de crédito</b>.
              </div>

              <div class="card-wrapper"></div>

              <div class="row mt-4">
                <div class="col-md-6 mb-3">
                  <label for="firstName">Número do cartão</label>
                  <ValidationProvider
                    name="número do cartão"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      :class="classes"
                      name="cardNumber"
                      v-model="dados.numCartao"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="lastName">Validade</label>
                  <ValidationProvider
                    name="validade"
                    rules="required|min:5"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      :class="classes"
                      name="cardExpiry"
                      placeholder="mm/aa"
                      v-mask="'##/##'"
                      v-model="dados.validadeCartao"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="lastName">CVC</label>
                  <ValidationProvider
                    name="cvc"
                    rules="required|min:2"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      :class="classes"
                      name="cardCvc"
                      v-model="dados.cvcCartao"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="firstName">Nome impresso no cartão</label>
                  <ValidationProvider
                    name="nome"
                    rules="required|min:10"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      :class="classes"
                      name="cardName"
                      v-model="dados.nomeCartao"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-6 text-right">
                  <img
                    class="mt-2"
                    src="/img/ssl-site-seguro.png"
                    width="120"
                  />
                </div>
              </div>
            </form>

            <hr />
            <div class="row">
              <div class="offset-md-4 col-md-8 text-right">
                <button
                  :disabled="loadingFinalizar || invalid"
                  class="btn btn-success btn-block"
                  @click.prevent="onClickRealizarPagamento()"
                >
                  <span v-if="loadingFinalizar">Por favor aguarde...</span>
                  <span v-if="!loadingFinalizar">Realizar pagamento</span>
                </button>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Card from "card";

import config from "../../config";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import validacaoExpiracaoCartaoCredito from "../../helpers/validacaoExpiracaoCartaoCredito";
import captureError from "../../helpers/captureError";
import helper from "../../helpers/helper";
import planoAnuncioCore from "../../core/planoAnuncio";

import BlocoMeuAnuncio from "../../components/Dashboard/BlocoMeuAnuncio";

export default {
  components: {
    BlocoMeuAnuncio,
  },
  data() {
    return {
      planoAnuncioCore,
      formatacaoMoeda,

      loadingGeral: false,
      loadingFinalizar: false,
      planoAnuncio: {},
      anuncio: {},
      dados: {},

      loadingCupom: false,
      cupomAplicado: false,
      cupom: undefined,
      valorAnterior: 0,
      valorTotal: 0,
      descontoPorcentagem: 0,
      descontoValor: 0,
    };
  },
  async mounted() {
    initCard();
    this.inicializarIugu();

    var idAnuncio = this.$route.params.idAnuncio;
    var idPlanoAnuncio = this.$route.params.idPlanoAnuncio;

    var result = await this.popularDadosNovaCobranca(idAnuncio, idPlanoAnuncio);
    if (result == false) {
      this.$router.replace("/dashboard/meus-anuncios");
    }
  },
  computed: {
    naoPrecisaDePagamento: function () {
      return this.valorTotal == 0;
    },
  },
  methods: {
    async popularDadosNovaCobranca(idAnuncio, idPlanoAnuncio) {
      try {
        this.loadingGeral = true;

        var response = await axios.get(
          `${config.API_URL}/cobranca/dadosNovaCobranca?idAnuncio=${idAnuncio}&idPlanoAnuncio=${idPlanoAnuncio}`
        );

        this.anuncio = response.data.anuncio;
        this.planoAnuncio = response.data.planoAnuncio;
        this.valorTotal = this.planoAnuncio.valor;

        this.loadingGeral = false;

        return true;
      } catch (e) {
        captureError(e);
        this.loadingGeral = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível carregar os dados. Tente novamente mais tarde ou contate o suporte.",
        });

        return false;
      }
    },
    async onClickRealizarPagamento() {
      let hash = undefined;
      if (!this.naoPrecisaDePagamento) {
        //Validando data expiração(validade) do cartão
        var validadeCartaoArr = this.obterValidadeCartao();
        var validadeCartaoResult = validacaoExpiracaoCartaoCredito(
          validadeCartaoArr[0],
          validadeCartaoArr[1]
        );

        if (validadeCartaoResult == false) {
          Swal.fire({
            icon: "warning",
            title: "Atenção",
            text: "O cartão informado está vencido. Por favor confira a data.",
          });
          return;
        }

        this.loadingFinalizar = true;

        hash = await this.gerarHashCartaoAsync();

        if (!hash) {
          this.loadingFinalizar = false;
          return;
        }
      }

      this.loadingFinalizar = true;
      var pago = await this.realizarPagamentoAsync(hash);

      if (!pago) {
        this.loadingFinalizar = false;
        return;
      }

      this.$router.replace("/dashboard/meus-anuncios");
    },
    async gerarHashCartaoAsync() {
      try {
        var resultNome = helper.parseNomeCompleto(this.dados.nomeCartao);

        var validade = this.obterValidadeCartao();

        var cardDataToHash = {
          number: this.dados.numCartao,
          expirationMonth: validade[0],
          expirationYear: validade[1],
          firstName: resultNome.primeiroNome,
          lastName: resultNome.restante,
          cvcQtdDigits: this.dados.cvcCartao?.length,
        };
        //var cardBData = btoa(JSON.stringify(cardDataToHash));

        cardDataToHash.cvc = this.dados.cvcCartao;
        cardDataToHash.cvcQtdDigits = undefined;

        var hash = await this.gerarHashIuguAsync(cardDataToHash);

        return hash;
      } catch (e) {
        captureError(e);

        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Por favor, verifique os dados do cartão. Se o erro persistir, tente utilizar outro cartão.",
        });
        return undefined;
      }
    },
    async realizarPagamentoAsync(hashCartao) {
      try {
        var data = {
          HashCartao: hashCartao,
          IdPlanoAnuncio: this.planoAnuncio.id,
          IdAnuncio: this.anuncio.id,
          CodigoCupom: this.cupom,
        };

        await axios.post(config.API_URL + "/cobranca", data);

        await Swal.fire("Compra realizada com sucesso!", "", "success");

        return true;
      } catch (error) {
        captureError(error);

        var motivo = error?.response?.data?.Data?.Motivo;
        if (motivo) {
          await Swal.fire({
            icon: "error",
            title: "Não foi possível concluir seu pagamento",
            text: "Motivo retornado pela operadora do cartão: " + motivo,
          });
        } else if (error?.response?.data?.cupomInvalido) {
          await Swal.fire({
            icon: "error",
            title: "",
            text: "Este cupom não existe ou não está mais disponível.",
          });
        } else {
          await Swal.fire({
            icon: "error",
            title: "Não foi possível concluir seu pagamento",
            text:
              "Por favor, confira os dados do cartão ou utilize outro. Se o erro persistir contate o suporte.",
          });
        }

        return false;
      }
    },
    obterValidadeCartao() {
      var mesCartao = 0;
      var anoCartao = 0;

      if (this.dados.validadeCartao) {
        var partes = this.dados.validadeCartao.split("/");
        mesCartao = partes[0];
        anoCartao = partes[1];
      }

      return [mesCartao, anoCartao];
    },
    inicializarIugu() {
      if (window["Iugu"]) {
        window["Iugu"].setAccountID(config.IUGU_ACCOUNT);
        window["Iugu"].setTestMode(config.IUGU_TEST_MODE);
      }
    },
    gerarHashIuguAsync(dados) {
      return new Promise((resolve, reject) => {
        //console.log("hashiugo", dados);
        var cc = window["Iugu"].CreditCard(
          dados.number,
          dados.expirationMonth,
          dados.expirationYear,
          dados.firstName,
          dados.lastName,
          dados.cvc
        );

        var valido = cc.valid();

        if (valido == false) {
          reject("Cartão inválido. Por favor verifique os dados.");
          return;
        }

        window["Iugu"].createPaymentToken(cc, function (data) {
          if (data.errors) {
            reject("Erro salvando cartão: " + JSON.stringify(data.errors));
          } else {
            resolve(data.id);
          }
        });
      });
    },

    async onClickAplicarCupom() {
      //Busca apenas se tiver digitado algum cupom
      if (!this.cupom) {
        return;
      }

      this.loadingCupom = true;

      try {
        var response = await axios.get(
          config.API_URL +
            "/cobranca/ValidarCupom?codigo=" +
            this.cupom +
            "&idPlanoAnuncio=" +
            this.planoAnuncio.id
        );
        this.loadingCupom = false;

        this.cupomAplicado = true;
        this.valorAnterior = this.planoAnuncio.valor;
        this.valorTotal = response.data.novoTotal;
        this.descontoPorcentagem = response.data.descontoPorcentagem;
        this.descontoValor = response.data.descontoValor;
      } catch (error) {
        this.loadingCupom = false;

        if (!error.response.data.disponivel) {
          Swal.fire({
            icon: "error",
            title: "",
            text: "Este cupom não existe ou não está mais disponível.",
          });
          return;
        }

        captureError(response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível aplicar o cupom. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};

function initCard() {
  new Card({
    // a selector or DOM element for the form where users will
    // be entering their information
    form: "#formStep3", // *required*
    // a selector or DOM element for the container
    // where you want the card to appear
    container: ".card-wrapper", // *required*

    formSelectors: {
      numberInput: 'input[name="cardNumber"]', // optional — default input[name="number"]
      expiryInput: 'input[name="cardExpiry"]', // optional — default input[name="expiry"]
      cvcInput: 'input[name="cardCvc"]', // optional — default input[name="cvc"]
      nameInput: 'input[name="cardName"]', // optional - defaults input[name="name"]
    },

    //width: 200, // optional — default 350px
    formatting: true, // optional - default true

    // Strings for translation - optional
    messages: {
      validDate: "valid\ndate", // optional - default 'valid\nthru'
      monthYear: "mm/yy", // optional - default 'month/year'
    },

    // Default placeholders for rendered fields - optional
    placeholders: {
      name: "SEU NOME",
    },

    // if true, will log helpful messages for setting up Card
    debug: false, // optional - default false
  });
}
</script>

<style scoped>
.plano-escolhido {
  border-radius: 10px;
}
</style>