<template>
    <div class="list-group">
          <template v-for="chat in items">
            <button
              :key="chat.Id"
              type="button"
              class="list-group-item list-group-item-action"
              :class="{'active': idSelectedItem == chat.id }"
              @click="onClickChat(chat)"
            >
              <div class="media align-items-center">
                <div
                  class="chat-list-img rounded-circle shadow-sm mr-3"
                  :style="{backgroundImage: 'url('+( chat.anuncioFoto || '/img/empty_img_circle.png')+')'}"
                ></div>
                <div class="media-body">                    
                  <b>{{chat.anuncioTitulo}}</b>                  
                  <div v-if="chat.proprietario">{{chat.nomeCliente}}</div>
                  <div v-else>{{chat.nomeProprietario}}</div>
                </div>
              </div>
            </button>
          </template>
        </div>
</template>

<script>
export default {
    props:["items", "idSelectedItem"],    
    methods:{
        onClickChat(item){
            this.$emit('click-chat', item)
        }
    }
}
</script>


<style lang="less" scoped>
@import "../../styles/variaveis";

.chat-list-img {
  width: 64px;
  height: 64px;
  //   background-image: url("/img/empty_img_circle.png");
  background-size: cover;
  background-position: center center;
}

.list-group-item.active{
    background-color: @light-gray-color;
    border-color: @light-gray-color;
    color:inherit;
}

button:focus{
    outline: none !important;
}
</style>