import moment from 'moment';

function validacaoExpiracaoCartaoCredito(mes, ano) {

    if (!mes || !ano) { return false; }

    var vMes = mes.trim();
    var vAno = ano.trim();
    if (vAno.length == 2) {
        vAno = "20" + vAno;
    }

    if (vMes.length == 1) {
        vMes = "0" + vMes;
    }

    var mExpireDate = moment(`${vAno}-${vMes}-01`);

    if (moment().isAfter(mExpireDate)) {
        //Cartão vencido
        return false;
    }

    return true;
}

export default validacaoExpiracaoCartaoCredito;