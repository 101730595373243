export default {

    smoothScrollYToElement: function(elm, duration, offsetY) {
        var elementY = window.pageYOffset + elm.getBoundingClientRect().top + (offsetY || 0);
        this.smoothScrollYToPosition(elementY, duration, offsetY);
    },

    smoothScrollYToPosition: function(elementY, duration) {
        if (duration === undefined) {
            duration = 700;
        }
        var startingY = window.pageYOffset;
        var diff = elementY - startingY;
        var start;

        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) start = timestamp;
            // Elapsed milliseconds since start of scrolling.
            var time = timestamp - start;
            // Get percent of completion in range [0, 1].
            var percent = Math.min(time / duration, 1);

            window.scrollTo(0, startingY + diff * percent);

            // Proceed with animation as long as we wanted it to.
            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        })
    },

    smoothScrollContainerToBottom: function(containerElm, duration) {
        if (duration === undefined) {
            duration = 700;
        }
        var startingY = containerElm.scrollTop;
        var diff = containerElm.scrollHeight - startingY;
        var start;

        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) start = timestamp;
            // Elapsed milliseconds since start of scrolling.
            var time = timestamp - start;
            // Get percent of completion in range [0, 1].
            var percent = Math.min(time / duration, 1);

            containerElm.scrollTo(0, startingY + diff * percent);

            // Proceed with animation as long as we wanted it to.
            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        })
    }

}