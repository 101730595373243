export default {

    getOptions(itensObj) {
        if (!itensObj) { return []; }

        var options = [];
        for (var key in itensObj) {
            options.push({ text: itensObj[key], value: key });
        }

        return options;
    },
    getOption(itensObj, value) {
        if (!itensObj) { return []; }

        for (var key in itensObj) {

            if (key == value) {
                return { text: itensObj[key], value: key };
            }
        }
        return undefined;
    },
    waitPromise(milliseconds) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, milliseconds);
        });
    },
    getOnlyNumbers(text) {
        if (!text) { return undefined; }
        return text.replace(/\D/g, "");
    },

    parseNomeCompleto(nomeCompleto) {

        var primeiroNome = "";
        var restante = "";

        if (nomeCompleto) {
            var espacoIdx = nomeCompleto.indexOf(" ");

            if (espacoIdx >= 0) {
                primeiroNome = nomeCompleto.substr(0, espacoIdx);
                restante = nomeCompleto.substr(espacoIdx + 1);
            } else {
                primeiroNome = nomeCompleto;
            }
        }

        return {
            primeiroNome: primeiroNome,
            restante: restante
        };
    },
}