<template>
  <div class="container text-left h-100">
    <div v-if="loadingChat" class="p-5">Carregando...</div>

    <!-- Empty state -->
    <div v-if="!loadingChat && chats && chats.length == 0" class="text-center mt-5">
      <img src="/img/IconeChat.png" width="300" class="img-fluid mb-3" />
      <h5>Você ainda não possui conversas de negociação.</h5>
      <p>Explore os anúncios da loja e entre em contato com alguém para inciar uma negociação.</p>
      <router-link class="btn btn-primary" to="/">
        <i class="fas fa-search mr-3"></i>
        Explorar Anúncios
      </router-link>
    </div>

    <!-- Chat -->
    <div
      class="row small-gutters chat-container h-100"
      v-if="!loadingChat && chats && chats.length > 0"
    >
      <!-- <div class="col-12" :class="chatSelecionado ? 'd-none d-md-block' : ''">
      
      </div>-->
      <div class="col-md-5 col-xl-4" :class="chatSelecionado ? 'd-none d-md-block' : ''">
        <h2 v-if="chatSelecionado && chatSelecionado.ativo==1" class="pt-3">Minhas negociações</h2>
        <h2
          v-else-if="chatSelecionado && !chatSelecionado.proprietario && chatSelecionado.ativo==2 "
          class="pt-3"
        >Minhas negociações</h2>
        <h2
          v-else-if="chatSelecionado && chatSelecionado.proprietario && chatSelecionado.ativo==3"
          class="pt-3"
        >Minhas negociações</h2>
        <!-- ChatList -->
        <ChatList
          :items="chats"
          :idSelectedItem="chatSelecionado && chatSelecionado.id"
          @click-chat="onClickChat"
        />
      </div>
      <div class="col-md-7 col-xl-8 my-md-5" :class="!chatSelecionado ? 'd-none d-md-block' : ''">
        <!-- ChatMessages -->
        <ChatMessages
          v-if="chatSelecionado"
          :chat="chatSelecionado"
          @voltar="onClickVoltar"
          @onExcluirChat="excluirChat"
        />
               <!-- Empty state (nenhum char selecionado) -->        
         <div v-else class="card">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-center text-center">
              <div>
                <img src="/img/IconeChat.png" class="img-fluid mb-3" />
                <h5>Selecione um chat no menu lateral.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import captureError from "../../../helpers/captureError";
import config from "../../../config";
import ChatList from "../../../components/Dashboard/ChatList";
import ChatMessages from "../../../components/Dashboard/ChatMessages";

export default {
  components: {
    ChatList,
    ChatMessages,
  },
  data() {
    return {
      chatSelecionado: undefined,
      loadingChat: false,
      chats: [],
      idAnuncio:undefined,
      bikeAnuncio:undefined
    };
  },
  created() {
    var idAnuncio = this.$route.query.a?.toLowerCase();
    var bikeAnuncioEncoded = this.$route.query.bike;

    var bikeAnuncio = undefined;
    if (bikeAnuncioEncoded) {
      bikeAnuncio = atob(decodeURIComponent(bikeAnuncioEncoded));
    }
this.idAnuncio=idAnuncio;
this.bikeAnuncio=bikeAnuncio;
    this.popularChat(idAnuncio, bikeAnuncio);
  },
  methods: {
    onClickChat(chat) {
      this.chatSelecionado = chat;
    },
    onClickVoltar() {
      this.chatSelecionado = undefined;
    },
    async excluirChat(chat) {
      this.loadingChat = true;
      try {
        var data = {
          Id: chat.id,
          EhProprietario: chat.proprietario,
        };
        //sweet alert por favor aguarde
        await axios.put(`${config.API_URL}/chat/`, data);
        await Swal.fire({
          icon: "success",
          title: "Conversa excluida com sucesso!",
        });
        this.loadingChat = false;
         this.popularChat(this.idAnuncio, this.bikeAnuncio);
      } catch (e) {
        captureError(e);
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi excluir a conversa. Tente novamente mais tarde ou contate o suporte.",
        });
        this.loadingChat = false;
      }
    },
    async popularChat(idAnuncio, bikeAnuncio) {
      try {
        this.loadingChat = true;

        var response = await axios.get(
          `${config.API_URL}/chat?idAnuncio=${idAnuncio || ""}`
        );

        this.chats = response.data;

        if (idAnuncio) {
          var chatAnuncio = this.chats.find(
            (item) => item.idAnuncio == idAnuncio
          );
          if (chatAnuncio) {
            this.chatSelecionado = chatAnuncio;
          } else {
            await Swal.fire({
              icon: "info",
              title: bikeAnuncio || "Anúncio sem mensagens",
              text: "Ainda não há mensagens de chat para este anúncio.",
            });
          }
        } else {
          //Ativa o chat inicial apenas se não tiver no mobile
          if (!this.isMobile()) {
            this.chatSelecionado =
              this.chats.length > 0 ? this.chats[0] : undefined;
          }
        }

        this.loadingChat = false;
      } catch (e) {
        captureError(e);
        this.loadingChat = false;
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível carregar o chat. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
</script>